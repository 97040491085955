import React from 'react';
import { graphql } from 'gatsby';
import WebTab from './_code-web';
import ReactNativeTab from './_code-reactnative';
import AndroidTab from './_code-android';
import AppleTab from './_code-apple';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../../components';
import pageHeroData from '../../../../data/pages/guidelines.yml';

const IndexPage = ({ data }) => {
  const lightColors = data.allColorsYaml.edges[0].node.light_environment_colors.concat(
    data.allColorsYaml.edges[1].node.light_environment_colors.filter(color =>
      color.css_var.includes('accent')
    )
  );

  const darkColors = data.allColorsYaml.edges[0].node.dark_environment_colors.concat(
    data.allColorsYaml.edges[1].node.dark_environment_colors.filter(color =>
      color.css_var.includes('accent')
    )
  );

  // Avoid mutating the underlying data
  const newData = {
    ...data,
    allColorsYaml: {
      ...data.allColorsYaml,
      edges: [
        {
          ...data.allColorsYaml.edges[0],
          node: {
            ...data.allColorsYaml.edges[0].node,
            light_environment_colors: lightColors,
            dark_environment_colors: darkColors,
          },
        },
      ],
    },
  };

  return (
    <PageWithSubNav
      pageType="code"
      title="Division"
      designCode
      subnav="guidelines">
      <PageHero heroData={pageHeroData} tierTwo="Layout" tierThree="Division" />

      <PlatformTabs
        pageType="guidelines"
        platforms={{
          web: <WebTab data={newData} />,
          reactnative: <ReactNativeTab data={newData} />,
          android: <AndroidTab data={newData} />,
          apple: <AppleTab data={newData} />,
        }}
      />
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query divisionGuidelinesCode($type: String = "/Theme|Divider/") {
    ...colorDataCode
  }
`;
